import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { IDashboardInterfaces } from 'src/app/interfaces/interfaces';
import { AuthService } from 'src/app/services/auth/auth.service';
import Swal from 'sweetalert2';
import { MatButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { MatIcon } from '@angular/material/icon';
import {
  MatFormField,
  MatLabel,
  MatSuffix,
} from '@angular/material/form-field';
import { MatDivider } from '@angular/material/divider';

@Component({
  selector: 'app-contact-me',
  templateUrl: './contact-me.component.html',
  styleUrls: ['./contact-me.component.css'],
  standalone: true,
  imports: [
    MatDivider,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatIcon,
    MatSuffix,
    MatInput,
    MatButton,
    TranslateModule,
  ],
})
export class ContactMeComponent implements OnInit {
  constructor(
    private toastr: ToastrService,
    private authservice: AuthService,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {}
  contactForm = new FormGroup({
    surname: new FormControl('', [
      Validators.required,
      Validators.pattern(/[A-Za-z]/),
    ]),
    email: new FormControl('', [Validators.required, Validators.email]),
    message: new FormControl('', Validators.required),
  });
  ngOnInit(): void {}
  keyPress(event: KeyboardEvent) {
    const pattern = /[A-Za-z]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  async send() {
    const translatedObject = await this.translate
      .get('app.landing.toast')
      .toPromise();
    if (this.contactForm.valid) {
      this.authservice
        .addStranger(
          Object.assign(this.contactForm.value, {
            lang: this.translate.currentLang,
          })
        )
        .subscribe((res: IDashboardInterfaces.Response) => {
          if (res.success) {
            this.dialog.closeAll();
            Swal.fire({
              icon: 'success',
              title: translatedObject.success,
            });
          } else {
            Swal.fire({
              icon: 'warning',
              title: translatedObject.warning,
            });
          }
        });
    } else {
      this.dialog.closeAll();
      setTimeout(() => {
        this.dialog.open(ContactMeComponent, {
          width: window.innerWidth > 768 ? '40%' : '90%',
        });
      }, 100);
      this.toastr.error(translatedObject.error, 'Problem', {
        tapToDismiss: false,
        positionClass: 'inline',
      });
    }
  }
}
