<div style="padding: 5%">
  <!-- <mat-icon>contact_support</mat-icon> 🫵!-->
  <div class="title">
    <h1>
      {{ "app.landing.contactModal.title" | translate }}
    </h1>
    <img class="icon" src="../../../assets/phonix-icon.webp" />
  </div>
  <mat-divider></mat-divider>
  <div style="margin-top: 1%">
    <form class="example-form" [formGroup]="contactForm">
      <mat-form-field
        class="example-full-width"
        style="margin-right: 2%"
        appearance="outline"
      >
        <mat-label>{{
          "app.landing.contactModal.prename" | translate
        }}</mat-label>
        <mat-icon matSuffix>contact_mail</mat-icon>
        <input
          (keypress)="keyPress($event)"
          type="text"
          matInput
          formControlName="surname"
        />
      </mat-form-field>
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-icon matSuffix>alternate_email</mat-icon>
        <mat-label>{{
          "app.landing.contactModal.email" | translate
        }}</mat-label>
        <input type="text" matInput formControlName="email" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="example-full-width">
        <mat-icon matSuffix>subject</mat-icon>
        <mat-label>{{ "app.landing.contactModal.why" | translate }}</mat-label>
        <textarea class="inputs" matInput formControlName="message"></textarea>
      </mat-form-field>
    </form>
  </div>
  <div>
    <p>
      {{ "app.contact.card.sendEmail.moreInfo" | translate }}
      <a
        href="https://calendly.com/research_for_phonix_health/15min"
        target="_blank"
      >
        {{ "app.contact.card.sendEmail.scheduleCall" | translate }}
      </a>
    </p>

    <div class="send">
      <button mat-button class="buttonModal" (click)="send()">
        {{ "app.contact.card.sendEmail.participateToStudy" | translate }}
      </button>
    </div>
  </div>
</div>
